const Button = ({ 
  children, title, type = 'button', onClick = null, className = '', textSize='text-sm',
  textColor = 'text-white', backgroundColor = 'bg-blue-500', disabled = false, padding = 'px-4 py-2', rounded ='rounded-md',
  ...props
}) => {
  const defaultClassName = ['flex justify-center items-center', rounded, padding,  className, textSize, textColor]
  defaultClassName.push(disabled ? 'bg-gray-300' : backgroundColor)

  return (
    <button
      className={defaultClassName.join(' ')}
      type={type}
      onClick={onClick}
      {...props}
    >
      {children || title}
    </button>
  )
}

export default Button
