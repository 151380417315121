import './Modal.scss'
import { TimesIcon } from '../../icons'
import { useNavigate } from 'react-router-dom'
import { classNames } from '../../utils'

const Modal = ({ children, onClose = () => {}, height = '', size = '', isActive = false }) => {
  const defaultClassName = ['modal', size, height].join(' ')
  const navigate = useNavigate()

  const closeModal = (e) => {
    e.preventDefault()

    onClose()
    navigate(-1, { replace: true })
  }

  return (
    <div className={classNames(defaultClassName, { 'is-active': isActive })} onClick={closeModal}>
      <div id='modal-content' className='modal-content' onClick={(e) => { e.stopPropagation() }}>
        {children}
      </div>

      <button type='button' className='close-button' onClick={closeModal}>
        <TimesIcon color='white' />
      </button>
    </div>
  )
}

export default Modal
