import { useContext } from 'react'
import { Header, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination } from '../../../components'
import { useFetch } from '../../../hooks/useFetch'
import { LocaleContext, TenantTokenContext } from '../../../contexts'
import { buildApiUrl, toCurrency } from '../../../utils'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { adminRoute } from '../../../routesHelpers'
import { translations } from '../../../locales'

const AdminOrders = () => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  
  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/pos/orders`, { ...Object.fromEntries(searchParams), locale })
  const { data } = useFetch(url, [])

  return (
    <>
      <Header title={translations[locale].orders.title}/>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Pedido</b></TableCell>
              <TableCell><b>Cliente</b></TableCell>
              <TableCell><b>Fecha de orden</b></TableCell>
              <TableCell className='text-right'><b>Precio</b></TableCell>
              <TableCell className='text-right'><b>Descuento</b></TableCell>
              <TableCell className='text-right'><b>Total</b></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            { data.data.map(record => {
              return(
                <TableRow key={record.id} className='hover:bg-slate-100 hover:cursor-pointer'
                  onDoubleClick={() => { navigate(adminRoute('showOrder', { orderToken: record.token }), { state: { previousLocation: location, modalSize: 'is-extra-large' }}) }}>
                  <TableCell>{record.display_number}</TableCell>
                  <TableCell>{record.customer_name}</TableCell>
                  <TableCell>{record.ordered_at}</TableCell>
                  <TableCell className='text-right'>{toCurrency(record.price)}</TableCell>
                  <TableCell className='text-right'>{toCurrency(record.discount_amount)}</TableCell>
                  <TableCell className='text-right'>{toCurrency(record.total)}</TableCell>
                </TableRow>
              )
            }) }
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-end'>
        { data.pagination && 
          <Pagination
            items={data.pagination.items} count={data.pagination.count} to={data.pagination.to}
            from={data.pagination.from} prev={data.pagination.prev} next={data.pagination.next}/>
        }
      </div>
    </>
  )
}

export default AdminOrders
