import { Navigate, useLocation } from 'react-router-dom'
import { setStoredLocation } from './utils'
import { route } from './routesHelpers'
import { useContext } from 'react'
import { TokenContext } from './contexts'

const PrivateRoute = ({ children }) => {
  const { token } = useContext(TokenContext)
  const location = useLocation()

  if (!token) {
    setStoredLocation(location.pathname)

    return <Navigate to={route('root')} replace/>
  }

  return children
}

export default PrivateRoute
