import { useState } from 'react'

export const useToken = () => {
  const getToken = () => {
    return localStorage.getItem('token')
  }
  const [token, setToken] = useState(getToken())

  const saveToken = (userToken) => {
    if (userToken === undefined || userToken === null) {
      localStorage.removeItem('token')
    } else {
      localStorage.setItem('token', userToken)
    }
    setToken(userToken)
  }

  return { setToken: saveToken, token }
}
