import { useContext, useState } from 'react'
import { LocaleContext } from '../../../contexts'
import { translations } from '../../../locales'
import {
  Button, TertiaryLink, Form, TextField, TextAreaField, SelectField, CheckboxField
} from '../../../components'
import { apiBaseUrl } from '../../../routesHelpers'

const ProductForm = ({ url, method, product = {}, onSuccess = () => {} }) => {
  const { locale } = useContext(LocaleContext)
  const [errors, setErrors] = useState({})

  return (
    <Form
      method={method}
      url={url}
      onSuccessfulSubmit={(data) => { onSuccess(data) }}
      onFailedSubmit={(errors) => { setErrors(errors) }}
    >
      <TextField type='hidden' method='locale' defaultValue={locale}/>

      <TextField
        method='octopus_os_product.name'
        placeholder={translations[locale].attributes.product.name}
        label={translations[locale].attributes.product.name}
        fieldClass='mb-4' serverError={errors.name}
        defaultValue={product?.name}
      />

      <SelectField
        method='octopus_os_product.unit'
        label={translations[locale].attributes.product.unit}
        fieldClass='flex-1' 
        fetchOptionsUrl={`${apiBaseUrl}/octopus_os/units?unit=${product.unit || ''}`}
        serverError={errors.unit}
        defaultValue={product?.unit_for_select || { label: 'g', value: 'g' }}
      />

      <TextAreaField
        method='octopus_os_product.description'
        placeholder={translations[locale].attributes.product.description}
        label={translations[locale].attributes.product.description}
        fieldClass='mb-4' serverError={errors.description}
        defaultValue={product?.description}
      />

      <TextField
        method='octopus_os_product.sat_product_code'
        label={translations[locale].attributes.product.satProductCode}
        fieldClass='mb-4' serverError={errors.sat_product_code}
        defaultValue={product?.sat_product_code}/>

      <TextField
        method='octopus_os_product.sat_unit_code'
        label={translations[locale].attributes.product.satUnitCode}
        fieldClass='mb-4' serverError={errors.sat_unit_code}
        defaultValue={product?.sat_unit_code}/>

      <CheckboxField
        method='octopus_os_product.subject_to_iva'
        label={translations[locale].attributes.product.subjectToIva}
        fieldClass='mb-4' serverError={errors.subject_to_iva}
        defaultValue={product?.subject_to_iva !== undefined ? product?.subject_to_iva : true}/>

      <CheckboxField
        method='octopus_os_product.subject_to_ieps'
        label={translations[locale].attributes.product.subjectToIeps}
        fieldClass='mb-4' serverError={errors.subject_to_ieps}
        defaultValue={product?.subject_to_ieps !== undefined ? product?.subject_to_ieps : false}/>

      <div className='flex justify-end gap-x-2'>
        <TertiaryLink href={-1} className='text-gray-500 font-bold'>{translations[locale].helpers.cancel.text}</TertiaryLink>
        <Button type='submit'>{translations[locale].helpers.save.text}</Button>
      </div>
    </Form>
  )
}

export default ProductForm
