const Header = ({ title, subtitle, children, className = '' }) => {
  return (
    <>
      <div className={`flex justify-between items-center mb-4 ${className}`}>
        <h1 className='font-bold text-3xl mt-0'>{title}</h1>

        <div className='flex'>
          {children}
        </div>
      </div>

      <p className='mt-0'>{subtitle}</p>
    </>
  )
}

export default Header
