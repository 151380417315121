import { useContext, useEffect } from 'react'
import { LocaleContext, TenantTokenContext } from '../../../contexts'
import { useFetch } from '../../../hooks'
import { AdminCashierCustomerInfo, AdminOrderItems } from './sections'
import { PrimaryLink } from '../../../components'
import { MoneyBillIcon, PlusIcon } from '../../../icons'
import { adminRoute } from '../../../routesHelpers'
import { useLocation, useNavigate } from 'react-router-dom'
import { buildApiUrl, toCurrency } from '../../../utils'
import { translations } from '../../../locales'
import OrderItemBySku from './OrderItemBySku'

const AdminCashier = () => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const location = useLocation()
  const navigate = useNavigate()
  const orderFromState = location?.state?.order
  
  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/pos/orders/new`, { locale }) 
  const { data } = useFetch(url, orderFromState || {}, [orderFromState])
  const order = data.data

  const currentPath = window.location.pathname

  useEffect(() => {
    const onKeyDown = (event) => {
      if (event.metaKey && event.key === 'i') {
        event.preventDefault();

        navigate(
          adminRoute('newCashierOrderItem', { orderToken: order.token }), 
          { state: { previousLocation: location, modalSize: 'is-large' } }
        )
      }
    }

    if (currentPath !== adminRoute('cashier')) {
      document.removeEventListener('keydown', onKeyDown)
      return
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [currentPath])

  return (
    <div className='flex flex-col grow gap-4'>
      <div className='flex flex-1 gap-4 justify-between'>
        <OrderItemBySku tenantToken={tenantToken} orderToken={order.token} locale={locale}/>

        <div>
          <PrimaryLink className='hover-smooth-shadow text-blue-500'
            href={adminRoute('newCashierOrderItem', { orderToken: order.token })} modal modalSize='is-large'>
            <PlusIcon/>
            <p className='ml-2'>{translations[locale].helpers.add.text} {translations[locale].models.orderItem.toLowerCase()}</p>
          </PrimaryLink>
        </div>
      </div>

      <div className='flex flex-2 gap-4'>
        <div className='flex flex-col bg-white flex-1 rounded-3xl p-4 shadow-smooth'>
          <AdminCashierCustomerInfo order={order}/>
        </div>
        
        <div className='flex flex-col bg-white flex-1 rounded-3xl p-4 items-center justify-center shadow-smooth'>
          <p className='font-bold text-5xl'>{toCurrency(order.total)} MXN</p>
        </div>
      </div>

      <div className='flex flex-8 bg-white rounded-3xl shadow-smooth'>
        <AdminOrderItems order={order}/>
      </div>

      <PrimaryLink className='flex flex-1 gap-4 justify-end hover-smooth-shadow'
        href={adminRoute('cashierOrderPayment', { orderToken: order.token })} modal modalSize='is-large'
        disabled={(order.order_items || []).length === 0}>
          <MoneyBillIcon />
          <p className='ml-2'>{translations[locale].helpers.charge.text}</p>
      </PrimaryLink>
    </div>
  )
}

export default AdminCashier