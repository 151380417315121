import { useNavigate } from 'react-router-dom'
import { TableCell, TableRow, TertiaryLink } from '../../../../../components'
import { MinusIcon, PlusIcon, TrashIcon, EditAltIcon } from '../../../../../icons'
import { toCurrency } from '../../../../../utils'
import { adminRoute, apiBaseUrl } from '../../../../../routesHelpers'
import { useContext } from 'react'
import { TenantTokenContext, TokenContext } from '../../../../../contexts'

const AdminOrderItem = ({ orderItem, orderToken }) => {
  const { token } = useContext(TokenContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const navigate = useNavigate()
  const amount = parseFloat(orderItem.amount)
  
  const destroyRecord = async () => {
    fetch(
      `${apiBaseUrl}/octopus_os/t/${tenantToken}/pos/orders/${orderToken}/items/${orderItem.id}`,
      { method: 'DELETE', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } }
    ).then(response => response.json())
    .then(data => {
      navigate(adminRoute('cashier'), { state: { order: data.data } })
    })
    .catch(() => {})   
  }

  const updateAmount = (amount) => {
    fetch(
      `${apiBaseUrl}/octopus_os/t/${tenantToken}/pos/orders/${orderToken}/items/${orderItem.id}`,
      { 
        method: 'PUT', 
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        body: JSON.stringify({ octopus_os_shop_order_item: { amount: amount } })
      }
    ).then(response => response.json())
     .then(data => {
        navigate(adminRoute('cashier'), { state: { order: data.data } })
     }).catch(() => {})
  }

  return (
    <TableRow>
      <TableCell className='align-middle'>
        <div className='flex'>
          <TertiaryLink href={adminRoute('editCashierOrderItem', { orderToken, orderItemId: orderItem.id })} modal>
            <EditAltIcon className='icon is-small'/>
          </TertiaryLink>
          {orderItem.amount}
        </div>
      </TableCell>
      <TableCell className='is-narrow align-middle'>
        <div className='flex gap-3'>
          <button type='button' className='text-blue-500' onClick={() => { updateAmount(amount + 1) }}>
            <PlusIcon />
          </button>

          <button type='button' 
            className='text-blue-500 disabled:text-gray-400' 
            onClick={() => { updateAmount(amount - 1) }}
            disabled={amount - 1 < 1}
          >
            <MinusIcon />
          </button>
        </div>
      </TableCell>
      <TableCell className='align-middle'>{orderItem.product_name} ({orderItem.sales_presentation_name})</TableCell>
      <TableCell className='text-right is-narrow align-middle'>{toCurrency(orderItem.unit_price)}</TableCell>
      <TableCell className='text-right is-narrow align-middle'>
        <div className='flex'>
          {toCurrency(orderItem.unit_discount)}
          <TertiaryLink href={adminRoute('editDiscountCashierOrderItem', { orderToken, orderItemId: orderItem.id })} modal>
            <EditAltIcon className='icon is-small'/>
          </TertiaryLink>
        </div>
      </TableCell>
      <TableCell className='text-right is-narrow align-middle'>{toCurrency(orderItem.total)}</TableCell>
      <TableCell className='is-narrow cursor-pointer align-middle' onClick={destroyRecord}><TrashIcon color='red'/></TableCell>
    </TableRow>
  )
}

export default AdminOrderItem