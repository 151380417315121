import { useContext } from "react"
import { Metric } from '../../../components'
import { TenantTokenContext } from "../../../contexts"

const AdminDashboard = () => {
  const { tenantToken } = useContext(TenantTokenContext)

  return (
    <div className="flex flex-col flex-1">
      <Metric title='Ventas de hoy' path={`/octopus_os/t/${tenantToken}/metrics/sales`}/>
    </div>
  )
}

export default AdminDashboard
