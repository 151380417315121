import { useState } from 'react'

const LOCALES = ['es', 'en']

export const useLocale = () => {
  const getLocale = () => {
    return localStorage.getItem('locale') || 'en'
  }
  const [locale, setLocale] = useState(getLocale())

  const saveLocale = (preferredLocale) => {
    if (!LOCALES.includes(preferredLocale)) {
      preferredLocale = 'en'
    }

    localStorage.setItem('locale', preferredLocale)
    setLocale(preferredLocale)
  }

  return { setLocale: saveLocale, locale }
}
