import { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { TokenContext } from '../../contexts'
import { useSearchParams } from 'react-router-dom'
import { buildQueryString } from '../../utils'

const Form = ({ 
  children, method, url, resetFieldsAfterSuccessfulSubmit = true, defaultValues = {},
  onFailedSubmit = () => {}, onSuccessfulSubmit = () => {}, updateSearchParamsOnly = false
}) => {
  const { token } = useContext(TokenContext)
  const methods = useForm({ defaultValues })
  const [, setSearchParams] = useSearchParams()

  const onSubmit = async (formParams) => {
    if (method === 'GET' && updateSearchParamsOnly) {
      setSearchParams(buildQueryString(formParams))
      return
    }

    const headers = { 'Content-Type': 'application/json' }
    const requestUrl = new URL(url)
    let body = JSON.stringify(formParams)
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }

    if (method === 'GET') {
      body = null

      for (const key of Object.keys(formParams)) {
        const value = formParams[key]

        if (typeof value === 'object') {
          for (const [param, nestedValue] of Object.entries(value)) {
            requestUrl.searchParams.append(`${key}[${param}]`, nestedValue)
          }
        } else {
          requestUrl.searchParams.append(key, value)
        }
      }
    }

    const response = await fetch(requestUrl.href, { method, body, headers: headers })

    const data = await response.json()

    if (response.ok) {
      if (resetFieldsAfterSuccessfulSubmit) { methods.reset() }

      onSuccessfulSubmit(data.data)
    } else if (data.errors) {
      onFailedSubmit(data.errors)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  )
}

export default Form
