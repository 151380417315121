import { useFormContext } from 'react-hook-form'
import { methodToDomId } from '../../utils'

const RadioGroup = ({
    method, serverError, disabled = false, rules = {}, labelClass = '', options = [], ...props
}) => {
  const { register }= useFormContext()
  const { onChange, onBlur, name, ref } = register(method, { disabled }); 

  return (
    <>
      {
        options.map(option => {
          const optionId = methodToDomId([method, option.value].join('_'))

          return(
            <label htmlFor={optionId} 
              key={optionId}
              className={`label w-full flex items-center px-4 py-3 gap-3 hover:bg-slate-100 rounded-lg has-[:checked]:bg-slate-200 ${labelClass}`}>
              <input
                id={optionId}
                ref={ref}
                onBlur={onBlur}
                onChange={onChange}
                name={name}
                type='radio'
                value={option.value}
                disabled={option.disabled}
                {...props}
                />
              {option.label}
            </label>  
          )
        })
      }
    </>
  )
}

export default RadioGroup
