import { useContext } from "react"
import { LocaleContext, TenantTokenContext } from "../../../../../contexts"
import { useParams } from "react-router-dom"
import { translations } from "../../../../../locales"
import { adminRoute } from "../../../../../routesHelpers"
import { TertiaryLink, Header, LabelValue } from "../../../../../components"
import { CheckCircleIcon, EditIcon, CircleXMarkIcon } from "../../../../../icons"
import { useFetch } from "../../../../../hooks/useFetch"
import { buildApiUrl } from "../../../../../utils"

const AdminProductGeneralInformation = ({ updatedAt }) => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { productId } = useParams()

  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/admin/products/${productId}`, { locale })
  const { data } = useFetch(url, {}, [updatedAt])
  const product = data.data
  
  return(
    <>
      <Header title={translations[locale].helpers.generalInformation.text}>
        <TertiaryLink href={adminRoute('editProduct', { id: productId })} modal={true}>
          <EditIcon/>
        </TertiaryLink>
      </Header>

      <LabelValue label={translations[locale].attributes.product.name} value={product.name} className='mb-4'/>
      <LabelValue label={translations[locale].attributes.product.description} value={product.description} className='mb-4'/>
      <LabelValue label={translations[locale].attributes.product.unit} value={product.unit} className='mb-4'/>
      <LabelValue label={translations[locale].attributes.product.satProductCode} value={product.sat_product_code} className='mb-4'/>
      <LabelValue label={translations[locale].attributes.product.satUnitCode} value={product.sat_unit_code} className='mb-4'/>
      <LabelValue label={translations[locale].attributes.product.subjectToIva} className='mb-4'>
        {
          product.subject_to_iva ?
            <CheckCircleIcon color='green'/>
          :
            <CircleXMarkIcon color="#ef4444"/>
        }
      </LabelValue>
      <LabelValue label={translations[locale].attributes.product.subjectToIeps}>
      {
          product.subject_to_ieps ?
            <CheckCircleIcon />
          :
            <CircleXMarkIcon color="#ef4444"/>
        }
      </LabelValue>
    </>
  )
}

export default AdminProductGeneralInformation;