import { useFormContext } from 'react-hook-form'
import { methodToDomId } from '../../utils'

const TextAreaField = ({ 
  label, method, placeholder, defaultValue, serverError, rules = {}, fieldClass = '', labelClass = '', type = 'text',
  rows=4, disabled = false
}) => {
  const id = methodToDomId(method)
  const { register }= useFormContext()
  const { onChange, onBlur, name, ref, value, error } = register(method, { value: defaultValue, disabled, ...rules }); 

  return (
    <div className={`field flex flex-col ${fieldClass}`}>
    {label && <label htmlFor={id} className={`label ${labelClass}`}>{label}</label>}
    <textarea
      id={id}
      ref={ref}
      onBlur={onBlur}
      onChange={onChange}
      name={name}
      placeholder={placeholder}
      type={type}
      value={value}
      rows={rows}
      disabled={disabled}
    >
    </textarea>
    {(error || serverError) && <p className='text-red-500 text-sm'>{error?.message || serverError}</p>}
  </div>
  )
}

export default TextAreaField
