import { buildApiUrl, toPercentage, parseInfinityFloat, toCurrency, classNames } from '../../utils'
import { useFetch } from '../../hooks'

const Metric = ({ title = '', path }) => {
  const url = buildApiUrl(path)
  const { data } = useFetch(url, {})
  const metric = data.data

  return (
    <div className="shadow-smooth flex flex-col rounded-3xl bg-white px-6 py-6">
      <div className="flex flex-1 flex-col justify-center items-center py-16">
        <p className="text-6xl">{toCurrency(metric.value)}</p>
        <p 
          className={classNames('text-base', { 'text-red-500': metric.diff < 0, 'text-green-500': metric.diff > 0 })}
        >
          {toPercentage(parseInfinityFloat(metric.diff))}
        </p>
      </div>
      <p className="text-2xl text-center text-gray-700">{title}</p>
    </div>
  )
}

export default Metric;