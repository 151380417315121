import { useContext } from 'react';
import { Form, TextField, Button } from '../../components'
import { LocaleContext } from '../../contexts';
import { translations } from '../../locales';

const SearchBar = ({
  url, method, placeholder = '...', onSuccessfulSubmit = () => {}, onFailedSubmit = () => {},
  updateSearchParamsOnly = true
}) => {
  const { locale } = useContext(LocaleContext)

  return(
    <Form
      method='GET'
      url={url}
      onSuccessfulSubmit={onSuccessfulSubmit}
      onFailedSubmit={onFailedSubmit}
      updateSearchParamsOnly={updateSearchParamsOnly}
      resetFieldsAfterSuccessfulSubmit={false}
    >
      <TextField type='hidden' method='locale' defaultValue={locale}/>

      <div className="flex gap-3 items-center">
        <TextField type='text' method={method} placeholder={placeholder} autoFocus fieldClass='w-full'/>

        <Button type='submit'>{translations[locale].helpers.search.text}</Button>
      </div>
    </Form>
  )
}

export default SearchBar;