import { useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import SalesPresentationForm from './SalesPresentationForm'
import { adminRoute } from '../../../../../routesHelpers'
import { translations } from '../../../../../locales'
import { Header } from '../../../../../components'
import { LocaleContext, TenantTokenContext } from '../../../../../contexts'
import { useFetch } from '../../../../../hooks/useFetch'
import { buildApiUrl } from '../../../../../utils'

const AdminEditSalesPresentation = () => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { productId, salesPresentationId } = useParams()
  const navigate = useNavigate()

  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/admin/products/${productId}/sales_presentations/${salesPresentationId}`, { locale })
  const { data } = useFetch(url)

  const handleOnSuccess = () => {
    navigate(
      adminRoute('showProduct', { productId }),
      { state: { productSalesPresentations: { updatedAt: Date.now() } } }
    )
  }

  return (
    <>
      <Header title={`${translations[locale].helpers.edit.text} ${translations[locale].models.salesPresentation.toLowerCase()}`} />

      {data.data &&
        <SalesPresentationForm
          url={url}
          method='PUT'
          onSuccess={handleOnSuccess}
          salesPresentation={data.data}
        />}

    </>
  )
}

export default AdminEditSalesPresentation
