import { Link, useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { LocaleContext, TokenContext } from '../../contexts'
import { route } from '../../routesHelpers'
import { MXFlagIcon, PowerOffIcon, USFlagIcon } from '../../icons'
import { classNames } from '../../utils'
import { translations } from '../../locales'
import { Button } from '../../components'

const Navbar = () => {
  const { token, setToken } = useContext(TokenContext)
  const { locale, setLocale } = useContext(LocaleContext)
  const navigate = useNavigate()

  const signOut = (e) => {
    e.preventDefault()

    setToken(null)
    navigate(route('root'), { replace: true })
  }

  return (
    <div className='navbar bg-white sticky top-0'>
      <div className='navbar--start' />

      <div className='navbar--end'>
        <div className='locale-switch mr-4'>
          <button type='button' className={classNames('', { 'is-active': locale === 'en' })} onClick={() => { setLocale('en') }}>
            <USFlagIcon />
          </button>
          <button type='button' className={classNames('', { 'is-active': locale === 'es' })} onClick={() => { setLocale('es') }}>
            <MXFlagIcon />
          </button>
        </div>
        {
          token ? 
            <Button type='button' textColor='text-red-500' backgroundColor='bg-white' className='hover-smooth-shadow' onClick={signOut}>
              <PowerOffIcon />
            </Button>
          :
            <Link to={route('root')} className='text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium'>
              {translations[locale].helpers.signIn.text}
            </Link>
        }
      </div>
    </div>
  )
}

export default Navbar
