import { useEffect, useRef, useState } from 'react'
import { Form, TextField } from '../../../components'
import { adminRoute } from '../../../routesHelpers'
import { buildApiUrl } from '../../../utils'
import { useNavigate } from 'react-router-dom'

const OrderItemBySku = ({ tenantToken, orderToken, locale }) => {
  const searchBarRef = useRef()
  const [errors, setErrors] = useState()
  const navigate = useNavigate()
  const currentPathname =  window.location.pathname

  const handleFailedSubmit = (data) => {  
    setErrors(data.sku)

    setTimeout(() => { setErrors(null) }, 3000)
  }

  const handleSuccessfulSubmit = (data) => {
    navigate(adminRoute('cashier'), { state: { order: data }, replace: true })
  }

  const onKeyDown = (event) => { 
    if (event.metaKey || (event.metaKey && event.key)) return
    
    searchBarRef.current?.focus()
  }

  useEffect(() => {
    const removeListener = () => {
      document.removeEventListener('keydown', onKeyDown)

      if (searchBarRef.current) {
        searchBarRef.current.value = ''
      }
    }

    if (currentPathname !== adminRoute('cashier')) {
      removeListener()
      return
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      removeListener()
    }
  }, [currentPathname]);

  return (
    <Form
      method='POST'
      url={buildApiUrl(`/octopus_os/t/${tenantToken}/pos/orders/${orderToken}/items`, { locale })}
      onSuccessfulSubmit={handleSuccessfulSubmit}
      onFailedSubmit={handleFailedSubmit}
    >
      <TextField method='sku' ref={searchBarRef} serverError={errors}/>
    </Form>
  )
}

export default OrderItemBySku;