import {
  useClick, useFloating, useInteractions, useListNavigation, autoUpdate, useTypeahead, FloatingList, FloatingPortal,
  FloatingFocusManager
  , useDismiss
} from '@floating-ui/react'
import { useState, useRef } from 'react'
import { DropdownContext } from './DropdownContext'
import { VerticalEllipsisIcon } from '../../icons'

const Dropdown = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  // eslint-disable-next-line
  const [hasFocusInside, setHasFocusInside] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)

  const elementsRef = useRef([])
  const labelsRef = useRef([])

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-end',
    whileElementsMounted: autoUpdate
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const listNavigation = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    onNavigate: setActiveIndex
  })

  const typeahead = useTypeahead(context, {
    listRef: labelsRef,
    onMatch: isOpen ? setActiveIndex : undefined,
    activeIndex
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    click, listNavigation, typeahead, dismiss
  ])

  useListNavigation(context, {
    listRef: elementsRef
  })

  return (
    <>
      <div className='w-6 h-6 flex items-center justify-center rounded-full hover:border-slate-200 hover:border  hover:cursor-pointer' ref={refs.setReference} {...getReferenceProps()}>
        <VerticalEllipsisIcon />
      </div>

      <DropdownContext.Provider
        value={{
          activeIndex,
          setActiveIndex,
          getItemProps,
          setHasFocusInside,
          isOpen
        }}
      >
        <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
          {isOpen && (
            <FloatingPortal>
              <FloatingFocusManager
                context={context}
                modal={false}
                initialFocus={0}
              >
                <div
                  ref={refs.setFloating}
                  className='menu bg-white rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none py-1'
                  style={floatingStyles}
                  {...getFloatingProps()}
                >
                  {children}
                </div>
              </FloatingFocusManager>
            </FloatingPortal>
          )}
        </FloatingList>
      </DropdownContext.Provider>
    </>
  )
}

export default Dropdown
