import { useState } from 'react'

export const useTenantToken = () => {
  const getTenantToken = () => {
    return localStorage.getItem('tenantToken')
  }
  const [tenantToken, setTenantToken] = useState(getTenantToken())

  const saveTenantToken = (tenantToken) => {
    if (tenantToken === undefined || tenantToken === null) {
      localStorage.removeItem('tenantToken')
    } else {
      localStorage.setItem('tenantToken', tenantToken)
    }
    setTenantToken(tenantToken)
  }

  return { setTenantToken: saveTenantToken, tenantToken }
}
