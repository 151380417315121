export const spanishLocales = {
  sidebar: {
    dashboard: 'Tablero',
    products: 'Productos',
    orders: 'Órdenes',
    cashier: 'Caja',
    customers: 'Clientes'
  },
  helpers: {
    add: { text: 'Agregar' },
    charge: { text: 'Cobrar' },
    new: {
      male: { text: 'Nuevo' },
      female: { text: 'Nueva' }
    },
    edit: { text: 'Editar' },
    save: { text: 'Guardar' },
    signOut: { text: 'Cerrar sesión' },
    signIn: { text: 'Iniciar sesión' },
    more: { text: 'Más' },
    less: { text: 'Menos' },
    cancel: { text: 'Cancelar' },
    delete: { text: 'Eliminar' },
    enable: { text: 'Activar' },
    generalInformation: { text: 'Información general' },
    search: { text: 'Buscar' }
  },
  models: {
    product: 'Producto',
    salesPresentation: 'Presentación de venta',
    productEquivalence: 'Equivalencia',
    order: 'Pedido',
    customer: 'Cliente',
    orderItem: 'Artículo'
  },
  attributes: {
    product: {
      name: 'Nombre',
      sku: 'SKU',
      description: 'Descripción',
      satUnitCode: 'Código de unidad SAT',
      satProductCode: 'Código de producto SAT',
      unit: 'Unidad',
      subjectToIva: 'Sujeto a IVA',
      subjectToIeps: 'Sujeto a IEPS'
    },
    salesPresentation: {
      name: 'Nombre',
      currency: 'Moneda',
      baseUnitPrice: 'Precio unitario',
      quantityValue: 'Contenido',
      unit: 'Unit',
      sku: 'SKU',
      taxesIncluded: 'Impuestos incluidos en el precio unitario'
    },
    customer: {
      firstName: 'Nombre',
      lastName: 'Apellido',
      email: 'Correo electrónico',
      phone: 'Teléfono',
      rfc: 'RFC',
      street: 'Calle',
      streetNumber: 'No. exterior',
      additionalInfo: 'No. interior, entre calles, etc',
      zipCode: 'Código postal',
      neighborhood: 'Colonia',
      city: 'Ciudad',
      state: 'Estado',
      country: 'País',
      legalName: 'Razón social',
      cfdiUse: 'Uso de CFDI'
    },
    order: {
      customer: 'Cliente'
    }
  },
  products: {
    title: 'Productos'
  },
  productEquivalences: {
    title: 'Equivalencia'
  },
  customers: {
    title: 'Clientes'
  },
  orders: {
    title: 'Pedidos'
  },
  cashier: {
    orderItems: { discount: { edit: { title: 'Descuento' } } }
  }
}
