import { useSearchParams } from 'react-router-dom'
import { ChevronLeftIcon, ChevronRightIcon } from '../../icons'

const Pagination = ({ items, prev, next, from, to, count }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const fetchRecords = (page) => {
    const queryParams = Object.fromEntries(searchParams)
    queryParams['page'] = page

    setSearchParams(queryParams)
  }

  return (
    <div className='flex gap-x-4 text-gray-400 text-sm mt-3'>
      <p>Rows per page {items}</p>

      <p>{from} - {to} of {count}</p>

      <div className='flex gap-x-2'>
        <button type='button' className='disabled:text-gray-300' disabled={!prev} onClick={() => { fetchRecords(prev) }}>
          <ChevronLeftIcon color='currentColor' />
        </button>
        <button type='button' className='disabled:text-gray-300' disabled={!next} onClick={() => { fetchRecords(next) }}>
          <ChevronRightIcon color='currentColor' />
        </button>
      </div>
    </div>
  )
}

export default Pagination
