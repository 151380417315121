import { useContext } from 'react'
import {
  TableBody, TableContainer, TableHead, TableRow, Table, TableCell, Dropdown, DropdownItem,
  Header,
  PrimaryLink
} from '../../../../../components'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { LocaleContext, TenantTokenContext } from '../../../../../contexts'
import { adminRoute, apiBaseUrl } from '../../../../../routesHelpers'
import { translations } from '../../../../../locales'
import { useFetch } from '../../../../../hooks/useFetch'
import { buildApiUrl, toCurrency } from '../../../../../utils'

const AdminProductSalesPresentations = ({ updatedAt }) => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { productId } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const url = buildApiUrl(
    `/octopus_os/t/${tenantToken}/admin/products/${productId}/sales_presentations`,
    { ...Object.entries(searchParams), locale }
  )
  const { data } = useFetch(url, [], [updatedAt])

  const destroySalesPresentation = async (id) => {
    if (!window.confirm('¿Desea eliminar esta presentación de venta?')) return

    const response = await fetch(
      `${apiBaseUrl}/octopus_os/t/${tenantToken}/admin/products/${productId}/sales_presentations/${id}`,
      { method: 'DELETE', headers: { 'Content-Type': 'application/json' } }
    )

    if (response.ok) {
      navigate(
        adminRoute('showProduct', { productId }),
        { state: { productSalesPresentations: { updatedAt: Date.now() } } }
      )
    }
  }

  return (
    <div>
      <Header title='Presentaciones de venta' subtitle=''>
        <PrimaryLink href={adminRoute('newSalesPresentation', { productId })} modal={true}>
          {translations[locale].helpers.new.male.text}
        </PrimaryLink>
      </Header>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className='font-bold' value='Nombre' />
              <TableCell className='font-bold text-center' value='SKU' />
              <TableCell className='font-bold text-center' value='Precio Unitario' />
              <TableCell className='font-bold text-center' value='Cantidad' />
              <TableCell className='font-bold' value='Proveedores' />
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {
              data.data.map((record) => {
                return (
                  <TableRow key={record.id} className='hover:bg-slate-100'>
                    <TableCell value={record.name} />
                    <TableCell className='text-center' value={record.sku} />
                    <TableCell className='text-center'  value={toCurrency(record.unit_price)} />
                    <TableCell className='text-center'  value={record.measure_quantity} />
                    <TableCell value={record.vendors} />
                    <TableCell className='has-text-right is-narrow'>
                      <Dropdown>
                        <DropdownItem
                          name={`${translations[locale].helpers.edit.text}`}
                          href={adminRoute('editSalesPresentation', { productId, salesPresentationId: record.id })}
                        />

                        <DropdownItem
                          name={`${translations[locale].helpers.delete.text}`}
                          textColor='text-red-500'
                          handleClick={() => destroySalesPresentation(record.id)}
                          disabled={!record.measurable_id}
                        />
                      </Dropdown>
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default AdminProductSalesPresentations
