const EditIcon = ({ color = 'currentColor' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' className='icon'>
      <path
        fill={color} d='M472 22a56 56 0 0 0-80 0l-30 30 98 98 30-30c22-22 22-58 0-80l-18-18zM172
        242c-6 6-10 13-13 22l-30 88a24 24 0 0 0 31 31l89-30c8-3 15-7 21-13l168-168-98-98-168 168zM96 64c-53
        0-96 43-96 96v256c0 53 43 96 96 96h256c53 0 96-43 96-96v-96a32 32 0 1 0-64 0v96c0 18-14 32-32
        32H96c-18 0-32-14-32-32V160c0-18 14-32 32-32h96a32 32 0 1 0 0-64H96z'
      />
    </svg>
  )
}

export { EditIcon }
