import { useContext, useState } from 'react'
import { Header, TextField, Form, SelectField, Button } from '../../../../../components/index.js'
import { translations } from '../../../../../locales/index.js'
import { LocaleContext, TenantTokenContext } from '../../../../../contexts.js'
import { useNavigate, useParams } from 'react-router-dom'
import { adminRoute, apiBaseUrl } from '../../../../../routesHelpers.js'
import { useFetch } from '../../../../../hooks/useFetch.js'
import { buildApiUrl } from '../../../../../utils.js'

const AdminEditProductEquivalence = () => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { productId } = useParams()
  const [errors, setErrors] = useState({})
  const navigate = useNavigate()

  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/admin/products/${productId}/equivalences`, { locale })
  const { data } = useFetch(url)
  const equivalence = data.data

  const onSuccessfulSubmit = () => {
    navigate(
      adminRoute('showProduct', { productId }),
      { state: { productEquivalence: { updatedAt: Date.now() } } }
    )
  }

  return (
    <>
      <Header title={`${translations[locale].helpers.edit.text} ${translations[locale].models.productEquivalence.toLowerCase()}`} />

      {
        equivalence &&
          <Form
            method='PUT'
            url={`${apiBaseUrl}/octopus_os/t/${tenantToken}/admin/products/${productId}/equivalences`}
            onSuccessfulSubmit={(data) => { onSuccessfulSubmit(data) }}
            onFailedSubmit={(errors) => { setErrors(errors) }}
          >
            <TextField type='hidden' method='locale' defaultValue={locale} />

            <div className='flex gap-4 items-center mb-4'>
              <TextField type='number' method='octopus_os_product_equivalence.from_value' defaultValue={equivalence.from_value} />
              <SelectField
                type='number' method='octopus_os_product_equivalence.from_unit'
                options={[equivalence.from_unit_for_select]}
                defaultValue={equivalence.from_unit_for_select}
                disabled serverError={errors.from_unit}
              />
              &nbsp;
              =
              &nbsp;
              <SelectField
                type='number' method='octopus_os_product_equivalence.to_unit'
                options={[equivalence.to_unit_for_select]}
                defaultValue={equivalence.to_unit_for_select}
                disabled
              />
            </div>

            <div className='flex justify-end'>
              <Button type='submit'>{translations[locale].helpers.save.text}</Button>
            </div>
          </Form>
      }
    </>
  )
}

export default AdminEditProductEquivalence
