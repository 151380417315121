import { Link, useLocation } from 'react-router-dom'

const TertiaryLink = ({ children, title, href, modal = true, modalSize = '', modalHeight = '', className = '', ...props }) => {
  const location = useLocation()

  return (
    <Link
      className={`flex justify-center items-center px-4 py-2 text-sm text-blue-600 rounded-md hover:underline ${className}`}
      to={href}
      state={modal ? { previousLocation: location, modalSize, modalHeight } : {}}
      {...props}
    >
      {children || title}
    </Link>
  )
}

export default TertiaryLink
