import './../../components/Navbar/Navbar.scss'
import { Navbar, Sidebar } from '../../components'
import { Outlet, NavLink } from 'react-router-dom'
import { adminRoute } from '../../routesHelpers'
import { translations } from '../../locales'
import { useContext } from 'react'
import { LocaleContext } from '../../contexts'

const AdminLayout = () => {
  const { locale } = useContext(LocaleContext)

  return (
    <div className='flex flex-1'>
      <Sidebar>
        <NavLink className='text-white' to={adminRoute('dashboard')}>{translations[locale].sidebar.dashboard}</NavLink>
        <NavLink className='text-white' to={adminRoute('customers')}>{translations[locale].sidebar.customers}</NavLink>
        <NavLink className='text-white' to={adminRoute('products')}>{translations[locale].sidebar.products}</NavLink>
        <NavLink className='text-white' to={adminRoute('orders')}>{translations[locale].sidebar.orders}</NavLink>
        <NavLink className='text-white' to={adminRoute('cashier')}>{translations[locale].sidebar.cashier}</NavLink>
      </Sidebar>

      <div className='flex flex-1 flex-col'>
        <Navbar />

        <div className='flex flex-1 flex-col p-16 items-center bg-slate-100'>
          <div className='flex flex-col flex-1 max-w-6xl w-full'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminLayout
