import AdminCustomerForm from './CustomerForm'
import { translations } from '../../../locales'
import { useContext } from 'react'
import { LocaleContext, TenantTokenContext } from '../../../contexts'
import { Header } from '../../../components'
import { adminRoute } from '../../../routesHelpers'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetch } from '../../../hooks/useFetch'
import { buildApiUrl } from '../../../utils'

const AdminEditCustomer = () => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { id } = useParams()
  const navigate = useNavigate()

  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/admin/customers/${id}`, { locale })
  const { data } = useFetch(url, null)

  const onSuccessfulSubmit = () => {
    navigate(
      adminRoute('customers'),
      { state: { adminCustomers: { updatedAt: Date.now() } } }
    )
  }

  return (
    <div>
      <Header title={`${translations[locale].helpers.edit.text} ${translations[locale].models.customer.toLowerCase()}`} />

      { data.data &&
            <AdminCustomerForm
              customer={data.data}
              url={url}
              method='PUT'
              onSuccess={onSuccessfulSubmit}/>
      }
    </div>
  )
}

export default AdminEditCustomer
