import './Table.scss'

const Table = ({ children, className = '', ...props }) => {
  return (
    <table className={`table is-fullwidth ${className}`} {...props}>
      {children}
    </table>
  )
}

export default Table
