import './Sidebar.scss'

const Sidebar = ({ children }) => {
  return (
    <div className='sidebar bg-slate-900 p-8'>
      <div className='sticky flex flex-col top-8'>
        {children}
      </div>
    </div>
  )
}

export default Sidebar
