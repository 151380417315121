import ProductForm from './ProductForm'
import { translations } from '../../../locales'
import { useContext } from 'react'
import { LocaleContext, TenantTokenContext } from '../../../contexts'
import { Header } from '../../../components'
import { adminRoute, apiBaseUrl } from '../../../routesHelpers'
import { useNavigate } from 'react-router-dom'

const AdminNewProduct = () => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const navigate = useNavigate()

  const onSuccessfulSubmit = () => {
    navigate(
      adminRoute('products'),
      { state: { adminProducts: { updatedAt: Date.now() } } }
    )
  }

  return (
    <div>
      <Header title={`${translations[locale].helpers.new.male.text} ${translations[locale].models.product.toLowerCase()}`} />

      <ProductForm
        url={`${apiBaseUrl}/octopus_os/t/${tenantToken}/admin/products`}
        method='POST'
        onSuccess={onSuccessfulSubmit}
      />
    </div>
  )
}

export default AdminNewProduct
