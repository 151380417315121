import { useContext, useState } from 'react'
import './SignIn.scss'
import { clearStoredLocation, getStoredLocation } from '../../utils'
import { Navigate, useNavigate } from 'react-router-dom'
import { TenantTokenContext, TokenContext } from '../../contexts'
import { adminRoute,apiBaseUrl } from '../../routesHelpers'
import { Button, Header } from '../../components'
import { EyeIcon, EyeSlashIcon } from '../../icons'

const signIn = async (email, password) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ user: { email, password } }),
    headers: { 'Content-Type': 'application/json' }
  }

  const response = await fetch(`${apiBaseUrl}/octopus_os/auth/sessions`, options)
  const data = await response.json()
  return { data, status: response.status, ok: response.ok }
}

const SignIn = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState([])
  const [passwordHidden, setPasswordHidden] = useState(true)
  const { token, setToken } = useContext(TokenContext)
  const { setTenantToken } = useContext(TenantTokenContext)
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()

    const { data, ok } = await signIn(email, password)
    const userToken = data?.token

    if (ok && userToken) {
      setToken(userToken)
      setTenantToken(data.tenant_token)

      const path = getStoredLocation() || adminRoute('dashboard')
      clearStoredLocation()
      navigate(path, { replace: true })
    } else {
      setPassword('')
      setErrors(data.errors || [])
    }
  }

  if (token) {
    return <Navigate to={adminRoute('dashboard')} replace/>
  }

  return (
    <div className='bg-slate-900 flex flex-1 flex-col'>
      <div id='sign-in-form' className='m-auto px-12 py-8 bg-slate-800 shadow-smooth rounded-3xl'>
        <Header title={'Iniciar sesión'} className='text-white'/>
        
        <form onSubmit={handleSubmit}>
          <div className='field mb-4'>
            <label htmlFor='email' className='text-white'>Correo electrónico</label>
            <div className='control'>
              <input id='email' type='email' placeholder='dumbledore@hogwarts.com' value={email} onChange={(e) => { setEmail(e.target.value) }} required />
              {errors.email && <p className='text-red-500 text-sm mt-1'>{errors.email}</p>}
            </div>
          </div>

          <div className='field-group'>
            <label htmlFor='password' className='text-white'>Contraseña</label>

            <div className='field has-addons'>
              <div className='control'>
                <input id='password' type={passwordHidden ? 'password' : 'text'} value={password} 
                  placeholder={passwordHidden ? '********' : 'password'}
                  onChange={(e) => { setPassword(e.target.value) }} required />
              </div>

              <div className='control'>
                <button type='button' onClick={() => { setPasswordHidden(previous => !previous) }}>
                  {
                    passwordHidden ?
                      <EyeIcon/>
                    :
                      <EyeSlashIcon/>
                  }
                </button>
              </div>
            </div>
            {errors.password && <p className='text-red-500 text-sm mt-1'>{errors.password}</p>}
          </div>

          <div className='mt-4 flex justify-center'>
            <Button type='submit'>
              Sign in
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SignIn
