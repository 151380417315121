import { useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import SalesPresentationForm from './SalesPresentationForm'
import { translations } from '../../../../../locales'
import { Header } from '../../../../../components'
import { adminRoute, apiBaseUrl } from '../../../../../routesHelpers'
import { LocaleContext, TenantTokenContext } from '../../../../../contexts'

const AdminNewSalesPresentation = () => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { productId } = useParams()
  const navigate = useNavigate()

  const handleOnSuccess = () => {
    navigate(
      adminRoute('showProduct', { productId }),
      { state: { productSalesPresentations: { updatedAt: Date.now() } } }
    )
  }

  return (
    <>
      <Header title={`${translations[locale].helpers.new.female.text} ${translations[locale].models.salesPresentation.toLowerCase()}`} />

      <SalesPresentationForm
        url={`${apiBaseUrl}/octopus_os/t/${tenantToken}/admin/products/${productId}/sales_presentations`}
        method='POST'
        onSuccess={handleOnSuccess}
      />
    </>
  )
}

export default AdminNewSalesPresentation
