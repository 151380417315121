import { useContext, useState } from "react"
import { Form, Header, SelectField, TextField, TertiaryLink, Button } from "../../../../../components"
import { translations } from "../../../../../locales"
import { LocaleContext, TenantTokenContext } from "../../../../../contexts"
import { useNavigate, useParams } from "react-router-dom"
import { adminRoute, apiBaseUrl } from "../../../../../routesHelpers"
import { useFetch } from "../../../../../hooks"
import { buildApiUrl } from "../../../../../utils"

const AdminEditCashierCustomerInfo = () => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const [errors, setErrors] = useState({})
  const { orderToken } = useParams()
  const navigate = useNavigate()

  const url = buildApiUrl(`${apiBaseUrl}/octopus_os/t/${tenantToken}/pos/orders/${orderToken}`, { locale })
  const { data } = useFetch(url)
  const order = data.data

  const onSuccessfulSubmit = (data) => {
    navigate(adminRoute('cashier'),  { state: { order: data } })
  }

  return (
    <>
      <Header title={`${translations[locale].helpers.edit.text} ${translations[locale].models.customer.toLowerCase()}`}/>
      
      { order && 
        <Form
          method='PUT'
          url={`${apiBaseUrl}/octopus_os/t/${tenantToken}/pos/orders/${orderToken}`}
          onSuccessfulSubmit={(data) => { onSuccessfulSubmit(data) }}
          onFailedSubmit={(errors) => { setErrors(errors) }}
        >
          <TextField type='hidden' method='locale' defaultValue={locale}/>

          <SelectField
            method='octopus_os_shop_order.octopus_os_shop_customer_id'
            fieldClass='flex-1 mb-4' 
            fetchOptionsUrl={`${apiBaseUrl}/octopus_os/t/${tenantToken}/pos/customers`}
            serverError={errors.customer}
            defaultValue={order.customer_for_select}
            isSearchable/>

          <div className='flex justify-end gap-x-2'>
            <TertiaryLink href={-1} className='text-gray-500 font-bold'>{translations[locale].helpers.cancel.text}</TertiaryLink>

            <Button type='submit'>{translations[locale].helpers.save.text}</Button>
          </div>
        </Form>
      }
    </>
  )
}

export default AdminEditCashierCustomerInfo